import { Col, Popover, Row } from 'antd';
import cn from 'classnames';
import {
  BlockMapBuilder,
  ContentBlock,
  ContentState,
  convertFromHTML,
  convertToRaw,
  DraftEditorCommand,
  DraftHandleValue,
  EditorState,
  Entity,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  RawDraftContentState,
  RichUtils,
  SelectionState,
} from 'draft-js';
/**
 * Editor END
 */
/**
 * Toolbar
 * */
import {
  BoldButton,
  ItalicButton,
  OrderedListButton,
  SubButton,
  SupButton,
  UnderlineButton,
  UnorderedListButton,
} from 'draft-js-buttons';
import ColorPicker, { colorPickerPlugin } from 'draft-js-color-picker';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
/**
 * Editor
 */
import Editor, { EditorPlugin } from 'draft-js-plugins-editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import { filterInlineStyles } from 'draftjs-filters';
import * as React from 'react';

import { IBlock, IRaw, Maybe } from '~common';
import { blockIsListItem, toHTML, toHTMLTry } from '~utils';

import buttonStyles from './buttonStyles.styl';
import { StrikeThroughButton } from './componets';
import {
  BUTTON_TOOLTIPS,
  disabledButtonsInStandardInput,
  disabledHotkeysInStandartInput,
  TOOLBAR_BUTTONS,
} from './constants';
import createLinkPlugin from './plugins/anchorPlugin';
import styles from './styles.styl';
import toolbarStyles from './toolbarStyles.styl';
/**
 * Toolbar END
 */
import { convertValueFromProps, isNeedReplace } from './utils';

const MSWordParagraphDelimiter = '<p class=MsoNormal><o:p>&nbsp;</o:p></p>';

const inlineStyles = ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH'];

const blocksToUnstyled = [
  'header-one',
  'header-two',
  'header-three',
  'header-four',
  'header-five',
  'header-six',
];

const customStyleMap = {
  SUBSCRIPT: {
    verticalAlign: 'sub',
    fontSize: '70%',
  },
  SUPERSCRIPT: {
    verticalAlign: 'super',
    fontSize: '70%',
  },
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
};

type ReplaceText = {
  regexp: RegExp | string;
  replace: string;
};

const replaceText: ReplaceText[] = [{ regexp: /\s-\s/g, replace: ' \u2014 ' }];

type OnBlurData = {
  html: Maybe<string>;
  raw: RawDraftContentState;
};

type OnChangeData = OnBlurData & {
  plainText: string;
};

export type OnChangeBlockDataHandler = (data: OnChangeData) => void;

interface IProps {
  addBlocks?(blocks: Partial<IBlock>[], index: number): void;
  blockIndex?: number;
  className?: string;
  disabledButtons?: unknown[];
  disabledHotkeys?: unknown[];
  inline?: boolean;
  isEditing?: boolean;
  needInline?: boolean;
  onBlur?(data: OnBlurData): void;
  onChange?(data: OnChangeData): void;
  placeholder?: string;
  preContentBlock?: boolean;
  rawContent?: unknown;
  rawData?: IRaw | string;
  readOnly?: boolean;
  replaceText?: ReplaceText[];
  value?: unknown;
  withColorPicker?: boolean;
  withPrefix?: boolean;
}

interface IState {
  contentString: string;
  dispatchOnChange?: NodeJS.Timeout;
  editorState: EditorState;
  html: Maybe<string>;
  preContentBlock?: boolean;
}

class RichEditor extends React.Component<IProps, IState> {
  editor: React.RefObject<Editor>;

  splitPasted: boolean;

  preContentBlock: boolean;

  LinkButton: React.ComponentType;

  Toolbar: React.ComponentType;

  InlineToolbar: React.ComponentType;

  plugins: EditorPlugin[];

  updateEditorState: (editorState) => void;

  getEditorState: () => EditorState;

  picker: any;

  constructor(props: IProps) {
    super(props);

    const {
      inline = true,
      preContentBlock = false,
      withColorPicker = false,
      needInline = false,
    } = props;

    this.editor = React.createRef();
    this.splitPasted = false;
    this.preContentBlock = preContentBlock;

    const linkPlugin = createLinkPlugin({ linkTarget: '_blank' });
    this.LinkButton = linkPlugin.LinkButton;

    const toolbarPlugin = createToolbarPlugin({ theme: { buttonStyles, toolbarStyles } });

    const inlineToolBarPlugin = createInlineToolbarPlugin();

    this.Toolbar = toolbarPlugin.Toolbar;

    this.InlineToolbar = inlineToolBarPlugin.InlineToolbar as React.ComponentType;

    this.plugins = [toolbarPlugin, linkPlugin, inlineToolBarPlugin];

    const rawData = convertValueFromProps(props.rawContent || props.rawData || props.value);

    const editorState = EditorState.createWithContent(rawData);
    const contentString = this.getContentString(rawData);

    if (inline) {
      import('draft-js-inline-toolbar-plugin/lib/plugin.css');
    }

    if (withColorPicker) {
      this.updateEditorState = editorState => {
        this.onChange(editorState);
      };
      this.getEditorState = () => this.state.editorState;
      this.picker = colorPickerPlugin(this.updateEditorState, this.getEditorState);
    }

    this.state = {
      editorState,
      html: null,
      contentString,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const rawData = convertValueFromProps(
      this.props.rawContent || this.props.rawData || this.props.value,
    );
    const prevRawData = convertValueFromProps(
      prevProps.rawContent || prevProps.rawData || prevProps.value,
    );

    const propsContentString = this.getContentString(rawData);
    const prevPropsContentString = this.getContentString(prevRawData);

    if (propsContentString !== prevPropsContentString) {
      const stateContentString = this.state.contentString;

      if (propsContentString !== stateContentString) {
        const editorState = EditorState.createWithContent(rawData);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          editorState,
          contentString: propsContentString,
        });
      }
    }

    if (prevProps.readOnly !== this.props.readOnly) {
      if (prevProps.readOnly) {
        if (this.state.editorState.getSelection().getHasFocus()) {
          this.focus();
        }
      }
    }
  }

  getContentString = (data: ContentState) =>
    JSON.stringify(convertToRaw(data)).replace(/"key":[^,]+,/g, '');

  focus = () => {
    this.editor.current?.focus();
  };

  getBlockText = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const block = contentState.getBlockForKey(selectionState.getAnchorKey());

    return block.getText();
  };

  replaceText = (editorState, regexp, replaceText) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const block = contentState.getBlockForKey(selectionState.getAnchorKey());

    const lastChangeType = editorState.getLastChangeType();

    const prevText = block
      .getText()
      .slice(selectionState.getEndOffset() - replaceText.length, selectionState.getEndOffset());

    if (
      prevText.search(regexp) >= 0 &&
      isNeedReplace(lastChangeType) &&
      this.getBlockText(editorState) !== this.getBlockText(this.state.editorState)
    ) {
      const index = selectionState.getEndOffset() - replaceText.length;
      const entityKey = Entity.create('MENTION', 'MUTABLE', {
        name: replaceText,
      });

      const replacedContent = Modifier.replaceText(
        contentState,
        new SelectionState({
          anchorKey: block.getKey(),
          anchorOffset: index,
          focusKey: block.getKey(),
          focusOffset: selectionState.getFocusOffset(),
          hasFocus: true,
        }),
        replaceText,
        undefined,
        entityKey,
      );
      return EditorState.push(editorState, replacedContent, 'apply-entity');
    }
    return editorState;
  };

  replaceQuotationMarks = editorState => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const block = contentState.getBlockForKey(selectionState.getAnchorKey());

    const lastChangeType = editorState.getLastChangeType();

    const hasQuotes = block.getText().match(/"([^"]*)"/gi);

    if (
      hasQuotes &&
      isNeedReplace(lastChangeType) &&
      this.getBlockText(editorState) !== this.getBlockText(this.state.editorState)
    ) {
      const firstQuoteIndex = block.getText().indexOf('"');

      let prevText = block.getText().slice(firstQuoteIndex, selectionState.getEndOffset());

      const index = selectionState.getEndOffset() - prevText.length;

      prevText = prevText.replace(/"([^"]*)"/gi, '\u00AB$1\u00BB');

      const replacedContent = Modifier.replaceText(
        contentState,
        new SelectionState({
          anchorKey: block.getKey(),
          anchorOffset: index >= 0 ? index : 0,
          focusKey: block.getKey(),
          focusOffset:
            index >= 0
              ? selectionState.getFocusOffset()
              : selectionState.getFocusOffset() + prevText.length - 1,
          hasFocus: true,
        }),
        prevText,
      );

      return EditorState.push(editorState, replacedContent, 'change-block-data');
    }

    return editorState;
  };

  onChange = editorState => {
    const isEditing = this.props?.isEditing ?? false;
    const isReadOnly = this.props?.readOnly ?? false;

    if (!isEditing && isReadOnly) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    editorState = (this.props.replaceText || replaceText).reduce(
      (editorState, { regexp, replace }) => this.replaceText(editorState, regexp, replace),
      editorState,
    );

    // eslint-disable-next-line no-param-reassign
    editorState = this.replaceQuotationMarks(editorState);

    if (this.splitPasted) {
      this.splitPasted = false;
      this.splitPastedState(editorState).then();
    } else {
      this.state.dispatchOnChange && clearTimeout(this.state.dispatchOnChange);

      /* ToDo fix two-way data binding */
      this.setState({
        editorState,
        html: toHTMLTry(editorState.getCurrentContent()),
        dispatchOnChange: global.setTimeout(() => {
          this.dispatchOnChange();
        }, 1000),
      });
    }
  };

  onBlur = () => {
    const { onBlur } = this.props;
    const rawData = this.state.editorState.getCurrentContent();
    let html = '';

    try {
      html = toHTML(rawData);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e, 'cannot convert to HTML; RichEditor 280;');
    }

    onBlur && onBlur({ raw: convertToRaw(rawData), html });
  };

  splitPastedState = async editorState => {
    const { addBlocks } = this.props;
    const { editorState: currentEditorState } = this.state;

    const currentContentState = this.state.editorState.getCurrentContent();
    const currentContentBlocksCount = currentContentState.getBlockMap().size;
    const contentState = editorState.getCurrentContent();

    if (contentState.getBlockMap().size - currentContentBlocksCount < 1) {
      this.onChange(EditorState.moveSelectionToEnd(editorState));
    }

    const currentFocusKey = currentEditorState.getSelection().getFocusKey();
    const startMoveFromBlockKey = contentState.getKeyAfter(currentFocusKey);
    const blockMapToKeep: ContentBlock[] = [];
    const blocksToMove: ContentBlock[] = [];

    let firstBlockToMoveReached = false;
    let isListSequence = false;

    contentState.getBlockMap().forEach(block => {
      if (!firstBlockToMoveReached && startMoveFromBlockKey === block.getKey()) {
        firstBlockToMoveReached = true;
      }

      if (firstBlockToMoveReached && !(isListSequence && blockIsListItem(block))) {
        blocksToMove.push(block);
        isListSequence = false;
      } else {
        blockMapToKeep.push(block);
        isListSequence = !blocksToMove.length && blockIsListItem(block);
      }
    });

    this.onChange(
      EditorState.moveFocusToEnd(
        EditorState.push(
          this.state.editorState,
          ContentState.createFromBlockArray(blockMapToKeep),
          'insert-characters',
        ),
      ),
    );

    const blocksToAdd: Partial<IBlock>[] = [];
    const insertIndex = this.preContentBlock ? 0 : this.getBlockIndex() + 1;

    for (let i = 0; i < blocksToMove.length; i += 1) {
      const nextBlockIndex = this.getBlockIndex() + i + 1;
      const block = blocksToMove[i];
      const blockMap = [block];

      // не разбивать списки на несколько блоков
      if (blockIsListItem(block)) {
        while (i < blocksToMove.length - 1 && blockIsListItem(blocksToMove[i + 1])) {
          const nextBlock = blocksToMove[i + 1];
          blockMap.push(nextBlock);
          i += 1;
        }
      }

      blocksToAdd.push({
        index: 0,
        rawText: convertToRaw(ContentState.createFromBlockArray(blockMap)),
      });
    }

    addBlocks && addBlocks(blocksToAdd, insertIndex);
  };

  getBlockIndex = () => this.props.blockIndex || 0;

  keyBindingFunction = event => {
    if (
      KeyBindingUtil.hasCommandModifier(event) &&
      event.shiftKey &&
      (event.key === 'x' || event.key === 'X' || event.key === 'Ч')
    ) {
      return 'strikethrough';
    }

    return getDefaultKeyBinding(event);
  };

  removeHeaders = editorState => {
    const contentState = editorState.getCurrentContent();
    let contentWithoutHeaders = contentState;
    let newEditorState = editorState;
    const blocksMap = contentState.getBlockMap();

    blocksMap.forEach(block => {
      const blockType = block.getType();
      if (blocksToUnstyled.includes(blockType)) {
        const selectionState = SelectionState.createEmpty(block.getKey());
        const updatedSelection = selectionState.merge({
          focusOffset: 0,
          anchorOffset: block.getText().length,
        });

        contentWithoutHeaders = Modifier.setBlockType(
          contentWithoutHeaders,
          updatedSelection,
          'unstyled',
        );
      }
    });

    const currentSelection = newEditorState.getSelection();

    newEditorState = EditorState.push(newEditorState, contentWithoutHeaders, 'change-block-type');

    newEditorState = EditorState.forceSelection(newEditorState, currentSelection);

    return newEditorState;
  };

  removeInlineStyles = (editorState: EditorState, retainInlineStyles: string[] = []) => {
    let newEditorState = editorState;

    const newContent = filterInlineStyles(retainInlineStyles, newEditorState.getCurrentContent());

    const currentSelection = newEditorState.getSelection();

    newEditorState = EditorState.push(newEditorState, newContent, 'change-inline-style');

    newEditorState = EditorState.forceSelection(newEditorState, currentSelection);

    return newEditorState;
  };

  // eslint-disable-next-line consistent-return
  handlePastedText = (
    text: string,
    html: string | undefined,
    editorState: EditorState,
  ): DraftHandleValue => {
    let newEditorState = editorState;

    if (/.*\n.+/.test(text) || (html && html.indexOf('<br>') > -1)) {
      this.splitPasted = true;
    }

    if (html) {
      const modifiedHtml = html
        // handle MS Word unordered list [19106]
        .replace(
          /<!\[if !supportLists\]>[\s\S]*?·?[\s\S]*?<!\[endif\]>([\s\S]*?<o:p>)/g,
          '<li>$1</li>',
        )
        // handle MS Word paragraphs [24146]
        .replace(MSWordParagraphDelimiter, '<br>');

      const blocksFromHTML = convertFromHTML(modifiedHtml);
      if (blocksFromHTML) {
        const { contentBlocks, entityMap } = blocksFromHTML;

        if (contentBlocks) {
          const htmlMap = BlockMapBuilder.createFromArray(contentBlocks);
          const newContent = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            htmlMap,
          );
          newContent.set('entityMap', entityMap);
          newEditorState = EditorState.push(editorState, newContent, 'insert-fragment');
          newEditorState = this.removeInlineStyles(newEditorState, inlineStyles);
          newEditorState = this.removeHeaders(newEditorState);
          this.onChange(newEditorState);
          return 'handled';
        }
      }
    }

    return 'not-handled';
  };

  // eslint-disable-next-line consistent-return
  handleKeyCommand = (
    command: DraftEditorCommand,
    prevEditorState: EditorState,
  ): DraftHandleValue => {
    const { inline = true, disabledHotkeys } = this.props;

    const disabledH = inline ? disabledHotkeysInStandartInput : disabledHotkeys;

    if (!disabledH?.includes(command)) {
      // inline formatting key commands handles bold, italic, code, underline
      let editorState = RichUtils.handleKeyCommand(prevEditorState, command);

      // If RichUtils.handleKeyCommand didn't find anything, check for our custom strikethrough command and call `RichUtils.toggleInlineStyle` if we find it.
      if (!editorState && command === 'strikethrough') {
        editorState = RichUtils.toggleInlineStyle(prevEditorState, 'STRIKETHROUGH');
      }

      if (editorState) {
        this.setState({ editorState });
        return 'handled';
      }
    }

    return 'not-handled';
  };

  dispatchOnChange() {
    const { onChange } = this.props;
    const { editorState } = this.state;
    const rawData = editorState.getCurrentContent();

    const contentString = this.getContentString(rawData);
    const stateContentString = this.state.contentString;

    // Проверяем изменился ли контент...
    if (stateContentString !== contentString) {
      // ... и если да запускаем обновление
      this.setState(
        {
          contentString,
        },
        () => {
          onChange &&
            onChange({
              raw: convertToRaw(rawData),
              plainText: rawData.getPlainText(),
              html: this.state.html,
            });
        },
      );
    }
  }

  render() {
    const { props, state, editor, plugins, Toolbar, InlineToolbar, LinkButton } = this;
    const {
      className,
      inline = true,
      needInline = false,
      placeholder = 'Введите текст',
      disabledButtons = [],
      readOnly = false,
      withPrefix = false,
      withColorPicker = false,
    } = props;
    const { editorState } = state;

    const allButtons = {
      [TOOLBAR_BUTTONS.BOLD]: BoldButton,
      [TOOLBAR_BUTTONS.ITALIC]: ItalicButton,
      [TOOLBAR_BUTTONS.UNDERLINE]: UnderlineButton,
      [TOOLBAR_BUTTONS.LINK]: LinkButton,
      [TOOLBAR_BUTTONS.UNORDERED_LIST]: UnorderedListButton,
      [TOOLBAR_BUTTONS.ORDERED_LIST]: OrderedListButton,
    };

    const disabledB = inline ? disabledButtonsInStandardInput : disabledButtons;
    const toolbarButtons = Object.keys(allButtons)
      .filter(button => disabledB.indexOf(button) === -1)
      .map(button => {
        return { buttonName: button, Button: allButtons[button] };
      });

    const inlineToolbarButtons = Object.keys(allButtons)
      .slice(0, 3)
      .map(button => {
        return { buttonName: button, Button: allButtons[button] };
      });

    return (
      <div
        className={
          className ||
          cn(
            styles.editor,
            inline && styles.inlineInput,
            readOnly && styles.disabled,
            withPrefix && styles.withPrefix,
          )
        }
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
      >
        <Editor
          ref={editor}
          spellCheck
          customStyleFn={withColorPicker ? this.picker.customStyleFn : null}
          customStyleMap={customStyleMap}
          editorState={editorState}
          placeholder={placeholder}
          onChange={this.onChange}
          onBlur={this.onBlur}
          plugins={plugins}
          handlePastedText={this.handlePastedText}
          handleKeyCommand={this.handleKeyCommand}
          keyBindingFn={this.keyBindingFunction}
          stripPastedStyles
          readOnly={readOnly}
          // TODO Прочитать документацию
          // pasteSupport={{
          //   inlineStyles: [
          //     'BOLD',
          //     'CODE',
          //     'ITALIC',
          //     'STRIKETHROUGH',
          //     'UNDERLINE',
          //     'SUBSCRIPT',
          //     'SUPERSCRIPT',
          //   ],
          //   blockTypes: [
          //     'header-one',
          //     'header-two',
          //     'header-three',
          //     'header-four',
          //     'header-five',
          //     'header-six',
          //     'unordered-list-item',
          //     'ordered-list-item',
          //     'blockquote',
          //     'atomic',
          //     'code-block',
          //     'unstyled',
          //   ],
          //   images: false,
          //   links: true,
          // }}
        />
        {!inline && (
          <Toolbar>
            {externalProps => (
              <Row style={{ width: '100%' }} align="middle">
                <Col>
                  {toolbarButtons.map(({ Button, buttonName }, index) => {
                    if (typeof Button === 'function') {
                      const popoverContent = BUTTON_TOOLTIPS[buttonName];
                      return popoverContent ? (
                        <Popover content={popoverContent}>
                          <span>
                            <Button {...externalProps} key={index} />
                          </span>
                        </Popover>
                      ) : (
                        <Button {...externalProps} key={index} />
                      );
                    }
                    return Button;
                  })}
                </Col>
                <Col flex="auto">
                  {withColorPicker && (
                    <Row style={{ width: '100%' }} justify="space-between" align="middle">
                      <Col className={styles.colorPickerWrapper}>
                        <ColorPicker
                          toggleColor={color => this.picker.addColor(color)}
                          color={this.picker.currentColor(editorState) || '#000000'}
                        />
                      </Col>
                      <Col>
                        <div className={styles.dropColor} onClick={this.picker.removeColor}>
                          СБРОСИТЬ ЦВЕТ
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            )}
          </Toolbar>
        )}
        {(inline || needInline) && (
          <InlineToolbar>
            {externalProps => (
              <Row align="middle" wrap>
                <Col>
                  {[
                    ...inlineToolbarButtons,
                    {
                      buttonName: TOOLBAR_BUTTONS.LINK,
                      Button: props => <LinkButton {...props} fromInline />,
                    },
                  ].map(({ Button, buttonName }, index) => {
                    if (typeof Button === 'function') {
                      const popoverContent = BUTTON_TOOLTIPS[buttonName];
                      return popoverContent ? (
                        <Popover content={popoverContent}>
                          <span>
                            <Button {...externalProps} key={index} />
                          </span>
                        </Popover>
                      ) : (
                        <Button {...externalProps} key={index} />
                      );
                    }
                    return Button;
                  })}
                </Col>
                <Col flex="auto">
                  {withColorPicker && (
                    <Row style={{ width: '100%' }} justify="space-between" align="middle">
                      <Col className={styles.colorPickerWrapper}>
                        <ColorPicker
                          toggleColor={color => this.picker.addColor(color)}
                          color={this.picker.currentColor(editorState) || '#000000'}
                        />
                      </Col>
                      <Col>
                        <div className={styles.dropColor} onClick={this.picker.removeColor}>
                          СБРОСИТЬ ЦВЕТ
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            )}
          </InlineToolbar>
        )}
      </div>
    );
  }
}

export default RichEditor;
