import { Col, Select } from 'antd';
import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';

import { ISubCategory } from '~common';
import { ItemWithLabel } from '~components';
import { useAuthorsStore, usePublicationStore, useSubcategoriesStore, useUserStore } from '~hooks';

const { Option } = Select;
const inputStyle = {
  width: '100%',
};

const findById = (array, id) => array.find(({ _id }) => _id === id);

export const CategoriesSelectGroup = observer(() => {
  const {
    dictionariesList,
    categories: publicationCategories,
    subcategories: publicationSubcategories,
    isQuiz,
    set,
  } = usePublicationStore();
  const { checkPermissions } = useUserStore();
  const { fetchCategoriesBySearch, categoriesSearches } = useAuthorsStore();
  const {
    fetchSubcategoriesBySearchAndCategoryIds,
    subcategoriesByCategories,
  } = useSubcategoriesStore();

  const selectedCategories = useMemo(
    () =>
      publicationCategories.map(categoryId => ({
        key: categoryId,
        label: (findById(dictionariesList.categories, categoryId) || { title: '' }).title,
      })),
    [publicationCategories],
  );

  const selectedSubcategories = useMemo(
    () =>
      publicationSubcategories.map(subcategoryId => ({
        key: subcategoryId,
        label: (findById(dictionariesList.subcategories, subcategoryId) || { title: '' }).title,
      })),
    [publicationSubcategories],
  );

  const filterOrphans = () => {
    const extendedSubcategories = localSubcategories.map(subcategory => ({
      ...subcategory,
      category: dictionariesList.subcategories.find(
        (elem: ISubCategory) => elem._id === subcategory.key, // @ts-ignore
      )?.category?._id,
    }));

    return extendedSubcategories.filter(elem =>
      localCategories.some(category => category.key === elem.category),
    );
  };

  const [localCategories, setLocalCategories] = useState(selectedCategories);
  const [localSubcategories, setLocalSubcategories] = useState(selectedSubcategories);
  const [needOrphanCheck, setNeedOrphanCheck] = useState(false);

  const fieldColLayout = { xs: 24, md: isQuiz ? 8 : 12 };

  useEffect(() => {
    setLocalCategories(selectedCategories);
    (async (): Promise<void> => {
      await fetchSubcategoriesBySearchAndCategoryIds(selectedCategories);
    })();
  }, [selectedCategories]);

  useEffect(() => {
    setLocalSubcategories(selectedSubcategories);
  }, [selectedSubcategories]);

  useEffect(() => {
    (async (): Promise<void> => {
      await fetchCategoriesBySearch('');
    })();
  }, []);

  useEffect(() => {
    if (needOrphanCheck) {
      const filteredSubcategories = filterOrphans();
      onChangeSubcategories(filteredSubcategories);
      setNeedOrphanCheck(false);
    }
  }, [localCategories]);

  const onChangeCategories = async categories => {
    if (categories && !isArray(categories)) {
      // eslint-disable-next-line no-param-reassign
      categories = [categories];
    }
    set({ name: 'categories', value: categories?.map(({ key }) => key) || [] });
    setNeedOrphanCheck(true);
    setLocalCategories(categories);
  };

  const onChangeSubcategories = subcategories => {
    if (subcategories && !isArray(subcategories)) {
      // eslint-disable-next-line no-param-reassign
      subcategories = [subcategories];
    }
    set({ name: 'subcategories', value: subcategories?.map(({ key }) => key) || [] });
    setLocalSubcategories(subcategories);
  };

  const onSubcategoriesSearch = async term => {
    await fetchSubcategoriesBySearchAndCategoryIds(selectedCategories, term);
  };

  const onBlurCategories = async () => {
    await fetchCategoriesBySearch('');
  };

  const onBlurSubcategories = async () => {
    await fetchSubcategoriesBySearchAndCategoryIds(selectedCategories);
  };

  return (
    <>
      <Col {...fieldColLayout}>
        <ItemWithLabel label="Категория">
          <Select
            style={inputStyle}
            mode={selectedCategories.length > 1 ? 'multiple' : undefined}
            placeholder="Выберите категорию"
            optionFilterProp="children"
            // @ts-ignore
            value={localCategories}
            onSearch={fetchCategoriesBySearch}
            onChange={onChangeCategories}
            onBlur={onBlurCategories}
            labelInValue
            maxTagCount={1}
            disabled={!checkPermissions('posts.settings')}
          >
            {categoriesSearches.map(({ _id, title }) => (
              <Option key={_id} value={_id}>
                {title}
              </Option>
            ))}
          </Select>
        </ItemWithLabel>
      </Col>
      <Col {...fieldColLayout}>
        <ItemWithLabel label="Подкатегория">
          <Select
            style={inputStyle}
            mode={selectedSubcategories.length > 1 ? 'multiple' : undefined}
            placeholder="Выберите подкатегорию"
            optionFilterProp="children"
            allowClear
            // @ts-ignore
            value={localSubcategories}
            onSearch={onSubcategoriesSearch}
            onChange={onChangeSubcategories}
            onBlur={onBlurSubcategories}
            labelInValue
            maxTagCount={1}
            disabled={!checkPermissions('posts.settings')}
          >
            {subcategoriesByCategories.map(({ _id, title }) => (
              <Option key={_id} value={_id}>
                {title}
              </Option>
            ))}
          </Select>
        </ItemWithLabel>
      </Col>
    </>
  );
});
